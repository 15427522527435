import { useFormikContext } from 'formik';
import React from 'react';
import { Grid, Input } from 'src/components/common';
import { SignUpFormKey, SignupFormValues } from '../../helpers';
import { useSignUp } from '../../SignUpProvider';

const AddressSection: React.FC<Props> = () => {
  const { getFieldProps } = useFormikContext<SignupFormValues>();

  const { getErrorMessage, handleChangeState } = useSignUp();

  return (
    <>
      <Grid.Item>
        <Input
          label={`Address`}
          required
          placeholder={`Enter your address`}
          errorMessage={getErrorMessage(SignUpFormKey.Address)}
          {...getFieldProps(SignUpFormKey.Address)}
        />
      </Grid.Item>
      <Grid.Item>
        <Input
          label={`City`}
          required
          placeholder={`Enter your city`}
          errorMessage={getErrorMessage(SignUpFormKey.City)}
          {...getFieldProps(SignUpFormKey.City)}
        />
      </Grid.Item>
      <Grid.Item>
        <Input
          label={`State`}
          required
          placeholder={`Enter your state`}
          errorMessage={getErrorMessage(SignUpFormKey.State)}
          {...getFieldProps(SignUpFormKey.State)}
          onChange={handleChangeState}
        />
      </Grid.Item>
      <Grid.Item>
        <Input
          label={`Zip Code`}
          required
          errorMessage={getErrorMessage(SignUpFormKey.ZipCode)}
          placeholder={`Enter your zip code`}
          {...getFieldProps(SignUpFormKey.ZipCode)}
        />
      </Grid.Item>
    </>
  );
};

type Props = {};

export default AddressSection;
