import { Yup } from 'src/services';
import { SchemaOf } from 'yup';

export enum Portal {
  Adjuster = 'AJ',
  Claimant = 'CLAIMANT',
  Employer = 'ER',
  HealthCareContractor = 'HC',
  InsuranceCarrier = 'CR',
  PhysicianInd = 'PS',
  RepresentativeInd = 'REPRESENTATIVE_IND',
  AgentOrg = 'AGENT_ORG',
  AttorneyEmployer = 'AE',
  AttorneyClaimant = 'AC',
  VocationalRehabilitationCounselor = 'VRC',
  BillDispute = 'BD',
  LegalGuardian = 'LG',
  PhysicianOrg = 'LG',
}

export enum UserClassification {
  Claimant = 'CL',
  Employer = 'ER',
  Insurance = 'IR',
  HealthCareProvider = 'HC',
  Representative = 'RP',
  Legal = 'LG',
  GovernmentAgency = 'Government Agency',
  Contractor = 'CT',
  Employee = 'EE',
}

export enum UserType {
  Employee = 'EE',
  Dependent = 'DP',
  Spouse = 'SP',
  Employer = 'ER',
  Investigation = 'Investigation',
  Insurance = 'Insurance',
  ThirdPartyAdmin = '3rd Party Administrator',
  Carrier = 'CR',
  Adjuster = 'AJ',
  Physician = 'PS',
  Other = 'OT',
  Attendant = 'Attendant',
  BillReview = 'BD',
  VocationalRehabilitationCounselor = 'VRC',
  SelfInsured = 'SI',
  LegalGuardian = 'LG',
  AttorneyForClaimant = 'AC',
  AttorneyForEmployer = 'AE',
  PowerOfAttorney = 'Power of Attorney',
  AttorneySpecialCompensationFund = 'Attorney (Special Compensation Fund)',
  AttorneyGeneralOffice = 'AT',
  CorporateCouncil = 'Corporate Council',
  LegalCounselForClaimant = 'Legal Counsel for Claimant',
  LegalCounselForEmployer = 'Legal Counsel for Employer',
  MedQuest = 'MedQuest',
  Medicare = 'Medicare',
  ChildSupport = 'Child Support',
  SocialSecurityOffice = 'Social Security Office',
  Audit = 'Audit',
  Contractor = 'Contractor',
}

export enum LegalServiceType {
  POWER_OF_ATTORNEY = 'Power of Attorney',
  ATTORNEY_SPECIAL_COMPENSATION_FUND = 'Attorney (Special Compensation Fund)',
  ATTORNEY_GENERAL = 'Attorney General',
  ATTORNEY = 'Attorney',
  LEGAL_COUNSEL = 'Legal Counsel',
  LEGAL_GUARDIAN = 'Legal Guardian',
  VOCATIONAL_REHABILITATION_COUNSELOR = 'Vocational Rehabilitation Counselor',
  SPECIAL_COMPENSATION_FUND = 'Special Compensation Fund',
}

export enum IslandOfService {
  OAHU = 'Oahu',
  HAWAII_ISLAND = 'Hawaii Island',
  MAUI = 'Maui',
  KAUAI = 'Kauai',
  MOLOKAI = 'Molokai',
  LANAI = 'Lanai',
  NIIHAU = 'Niihau',
}

export enum GovernmentAgencyService {
  MEDQUEST = 'MedQuest',
  PUBLIC_HEALTH_BENEFITS = 'Public Health benefits',
  SOCIAL_SECURITY = 'Social Security',
  CHILD_SUPPORT = 'Child Support',
}

export enum ProgramType {
  WC = 'WC',
  TDI = 'TDI',
  PHC = 'PHC',
}

export type AdjustingService = Exclude<ProgramType, ProgramType.PHC>;

export const toArrayValue = (values: OptionType[]) => values?.map(({ value }) => value);

export interface OptionType {
  value: any;
  label: string;
  key?: string;
}

export const isContain = (parentArray: Array<any>, array: Array<any>) =>
  parentArray.some((element) => array.includes(element));

export const isParentArray = (parentArray: Array<any>, array: Array<any>) =>
  array.every((element) => parentArray?.includes(element));

export const legalServiceTypeOptions = [
  { value: LegalServiceType.ATTORNEY, label: 'Attorney' },
  { value: LegalServiceType.POWER_OF_ATTORNEY, label: 'Power of Attorney' },
  { value: LegalServiceType.LEGAL_COUNSEL, label: 'Legal Counsel' },
  { value: LegalServiceType.LEGAL_GUARDIAN, label: 'Legal Guardian' },
  {
    value: LegalServiceType.VOCATIONAL_REHABILITATION_COUNSELOR,
    label: 'Vocational Rehabilitation Counselor',
  },
  { value: LegalServiceType.ATTORNEY_GENERAL, label: 'Attorney General' },
  { value: LegalServiceType.SPECIAL_COMPENSATION_FUND, label: 'Special Compensation Fund' },
];

export const governmentAgencyServiceOptions = [
  { value: GovernmentAgencyService.MEDQUEST, label: 'MedQuest' },
  { value: GovernmentAgencyService.PUBLIC_HEALTH_BENEFITS, label: 'Public Health benefits' },
  { value: GovernmentAgencyService.SOCIAL_SECURITY, label: 'Social Security' },
  { value: GovernmentAgencyService.CHILD_SUPPORT, label: 'Child Support' },
];

export const programTypeOptions = [
  { value: ProgramType.WC, label: `Workers' Compensation` },
  { value: ProgramType.TDI, label: 'Temporary Disability Insurance' },
  { value: ProgramType.PHC, label: 'Prepaid Health Care' },
];

export const adminServiceOptions = [
  { value: ProgramType.WC, label: 'WC' },
  { value: ProgramType.TDI, label: 'TDI' },
];

export const islandServiceOptions = [
  { value: IslandOfService.OAHU, label: 'Oahu' },
  { value: IslandOfService.HAWAII_ISLAND, label: 'Hawaii Island' },
  { value: IslandOfService.MAUI, label: 'Maui' },
  { value: IslandOfService.KAUAI, label: 'Kauai' },
  { value: IslandOfService.MOLOKAI, label: 'Molokai' },
  { value: IslandOfService.LANAI, label: 'Lanai' },
  { value: IslandOfService.NIIHAU, label: 'Niihau' },
];

export enum ServiceType {
  PhysicianAssistant = 'Physician assistant',
  Medicine = 'Medicine',
  APRN = 'APRN',
  Dentist = 'Dentist',
  Chiropractor = 'Chiropractor',
  NaturoPath = 'Naturo Path',
  Psychologist = 'Psychologist',
  Optometrist = 'Optometrist',
  Podiatrist = 'Podiatrist',
  Specialist = 'Specialist',
}

export enum OtherServiceType {
  OccupationalTherapist = 'Occupational Therapist',
  PhysicalTherapist = 'Physical Therapist',
  Massage = 'Massage',
  Accupuncturist = 'Accupuncturist',
}

export const otherServiceTypeOptions = [
  { value: OtherServiceType.OccupationalTherapist, label: 'Occupational Therapist' },
  { value: OtherServiceType.PhysicalTherapist, label: 'Physical Therapist' },
  { value: OtherServiceType.Massage, label: ' Massage' },
  { value: OtherServiceType.Accupuncturist, label: 'Accupuncturist' },
];

export const serviceTypeOptions = [
  { value: ServiceType.PhysicianAssistant, label: ' Physician assistant' },
  { value: ServiceType.Medicine, label: ' Medicine' },
  { value: ServiceType.APRN, label: ' APRN' },
  { value: ServiceType.Dentist, label: 'Dentist' },
  { value: ServiceType.Chiropractor, label: 'Chiropractor' },
  { value: ServiceType.NaturoPath, label: 'Naturo Path' },
  { value: ServiceType.Optometrist, label: 'Optometrist' },
  { value: ServiceType.Psychologist, label: 'Psychologist' },
  { value: ServiceType.Podiatrist, label: ' Podiatrist' },
  { value: ServiceType.Specialist, label: 'Specialist' },
];

export const OtherServiceTypeOptions = [
  { value: ServiceType.PhysicianAssistant, label: ' Physician assistant' },
  { value: ServiceType.Medicine, label: ' Medicine' },
  { value: ServiceType.APRN, label: ' APRN' },
  { value: ServiceType.Dentist, label: 'Dentist' },
  { value: ServiceType.Chiropractor, label: 'Chiropractor' },
  { value: ServiceType.NaturoPath, label: 'Naturo Path' },
  { value: ServiceType.Optometrist, label: 'Optometrist' },
  { value: ServiceType.Psychologist, label: 'Psychologist' },
  { value: ServiceType.Podiatrist, label: ' Podiatrist' },
  { value: ServiceType.Specialist, label: 'Specialist' },
];

export enum IdentificationType {
  SSN = 'Social Security Number',
  Passport = 'Passport',
}

export enum OrganizationType {
  Corporation = 'Corporation',
  Partnership = 'Partnership',
  SoleProprietor = 'Sole Proprietor',
  Other = 'Other',
}

export const organizationTypeOptions = [
  {
    value: OrganizationType.Corporation,
    label: 'Corporation',
  },
  {
    value: OrganizationType.Partnership,
    label: 'Partnership',
  },
  {
    value: OrganizationType.SoleProprietor,
    label: 'Sole Proprietor',
  },
  {
    value: OrganizationType.Other,
    label: 'Other',
  },
];

export const identificationTypeOptions = [
  {
    value: IdentificationType.SSN,
    label: 'Social Security Number',
  },
  {
    value: IdentificationType.Passport,
    label: 'Passport',
  },
];

export const userClassificationOptions = [
  {
    value: UserClassification.Claimant,
    label: 'Claimant',
    notInProgramTypeCondition: [ProgramType.PHC],
  },
  {
    value: UserClassification.Employer,
    label: 'Employer',
    notInProgramTypeCondition: [],
  },
  {
    value: UserClassification.Insurance,
    label: 'Insurance',
    notInProgramTypeCondition: [],
  },
  {
    value: UserClassification.Employee,
    label: 'Employee',
    notInProgramTypeCondition: [ProgramType.WC, ProgramType.TDI],
  },

  {
    value: UserClassification.HealthCareProvider,
    label: 'Health Care Provider',
    notInProgramTypeCondition: [ProgramType.PHC],
    programTypeCondition: [ProgramType.WC, ProgramType.TDI],
  },
  {
    value: UserClassification.Representative,
    label: 'Representative',
    notInProgramTypeCondition: [ProgramType.PHC],
  },
  {
    value: UserClassification.Legal,
    label: 'Legal',
    notInProgramTypeCondition: [ProgramType.PHC],
  },
  {
    value: UserClassification.GovernmentAgency,
    label: 'Government Agency',
    notInProgramTypeCondition: [ProgramType.PHC, ProgramType.TDI, ProgramType.WC],
  },
  {
    value: UserClassification.Contractor,
    label: 'Contractor',
    notInProgramTypeCondition: [ProgramType.TDI, ProgramType.WC],
  },
];

export const userTypeOptions = [
  {
    value: UserType.Employee,
    label: 'Employee',
    userClassificationCondition: [UserClassification.Claimant, UserClassification.Employee],
    portal: Portal.Claimant,
  },
  {
    value: UserType.Dependent,
    label: 'Dependent',
    userClassificationCondition: [UserClassification.Claimant],
    portal: Portal.Claimant,
  },
  {
    value: UserType.Spouse,
    label: 'Spouse',
    userClassificationCondition: [UserClassification.Claimant],
    portal: Portal.Claimant,
  },
  {
    value: UserType.Employer,
    label: 'Employer',
    userClassificationCondition: [UserClassification.Employer],
    portal: Portal.Employer,
  },
  {
    value: UserType.Audit,
    label: 'Audit',
    userClassificationCondition: [],
    portal: Portal.Employer,
  },
  {
    value: UserType.Investigation,
    label: 'Investigation',
    userClassificationCondition: [],
    portal: Portal.Employer,
  },
  {
    value: UserType.Insurance,
    label: 'Insurance',
    userClassificationCondition: [],
    portal: Portal.Employer,
  },
  {
    value: UserType.Carrier,
    label: 'Carrier',
    userClassificationCondition: [UserClassification.Insurance],
    programTypeCondition: [ProgramType.PHC],
    portal: Portal.InsuranceCarrier,
  },
  {
    value: UserType.ThirdPartyAdmin,
    label: '3rd Party Administrator',
    userClassificationCondition: [],
    portal: Portal.Adjuster,
  },
  {
    value: UserType.SelfInsured,
    label: 'Self-Insured',
    userClassificationCondition: [UserClassification.Insurance],
    portal: Portal.InsuranceCarrier,
  },
  {
    value: UserType.Adjuster,
    label: 'Adjuster',
    userClassificationCondition: [UserClassification.Insurance],
    programTypeCondition: [ProgramType.PHC],
    portal: Portal.Adjuster,
  },
  {
    value: UserType.Physician,
    label: 'Physician',
    userClassificationCondition: [UserClassification.HealthCareProvider],
    programTypeCondition: [ProgramType.TDI],
    portal: Portal.PhysicianInd,
  },
  {
    value: UserType.Other,
    label: 'Other',
    userClassificationCondition: [],
    portal: Portal.PhysicianInd,
  },
  {
    value: UserType.Attendant,
    label: 'Attendant',
    userClassificationCondition: [],
    portal: Portal.RepresentativeInd,
  },
  {
    value: UserType.BillReview,
    label: 'Bill Dispute',
    userClassificationCondition: [UserClassification.Representative],
    programTypeCondition: [ProgramType.TDI],
    portal: Portal.BillDispute,
  },
  {
    value: UserType.VocationalRehabilitationCounselor,
    label: 'Vocational Rehabilitation Counselor',
    userClassificationCondition: [UserClassification.Representative],
    programTypeCondition: [ProgramType.TDI],
    portal: Portal.VocationalRehabilitationCounselor,
  },
  {
    value: UserType.PowerOfAttorney,
    label: 'Power of Attorney',
    userClassificationCondition: [],
    portal: Portal.RepresentativeInd,
  },
  {
    value: UserType.LegalGuardian,
    label: 'Legal Guardian',
    userClassificationCondition: [UserClassification.Representative],
    portal: Portal.LegalGuardian,
  },
  {
    value: UserType.AttorneyForClaimant,
    label: 'Attorney for Claimant',
    userClassificationCondition: [UserClassification.Legal],
    portal: Portal.AttorneyClaimant,
  },
  {
    value: UserType.AttorneyForEmployer,
    label: 'Attorney for Employer',
    userClassificationCondition: [UserClassification.Legal],
    portal: Portal.AttorneyEmployer,
  },
  {
    value: UserType.AttorneySpecialCompensationFund,
    label: 'Attorney (Special Compensation Fund)',
    userClassificationCondition: [],
    portal: Portal.InsuranceCarrier,
  },
  {
    value: UserType.AttorneyGeneralOffice,
    label: 'Attorney (Attorney General Office)',
    userClassificationCondition: [UserClassification.Legal],
    portal: Portal.Claimant,
  },
  {
    value: UserType.CorporateCouncil,
    label: 'Corporate Council',
    userClassificationCondition: [],
    portal: Portal.RepresentativeInd,
  },
  {
    value: UserType.LegalCounselForClaimant,
    label: 'Legal Counsel for Claimant',
    userClassificationCondition: [],
    portal: Portal.Claimant,
  },
  {
    value: UserType.LegalCounselForEmployer,
    label: 'Legal Counsel for Employer',
    userClassificationCondition: [],
    portal: Portal.Employer,
  },
  {
    value: UserType.MedQuest,
    label: 'MedQuest',
    userClassificationCondition: [UserClassification.GovernmentAgency],
    portal: Portal.RepresentativeInd,
  },
  {
    value: UserType.Medicare,
    label: 'Medicare',
    userClassificationCondition: [UserClassification.GovernmentAgency],
    portal: Portal.RepresentativeInd,
  },
  {
    value: UserType.ChildSupport,
    label: 'Child Support',
    userClassificationCondition: [UserClassification.GovernmentAgency],
    portal: Portal.RepresentativeInd,
  },
  {
    value: UserType.SocialSecurityOffice,
    label: 'Social Security Office',
    userClassificationCondition: [UserClassification.GovernmentAgency],
    portal: Portal.RepresentativeInd,
  },
  {
    value: UserType.Contractor,
    label: 'Health Care Contractor',
    userClassificationCondition: [UserClassification.Contractor],
    portal: Portal.HealthCareContractor,
  },
];

export const portalOptions = [
  { value: Portal.Adjuster, label: 'Adjuster' },
  { value: Portal.Claimant, label: 'Claimant' },
  { value: Portal.Employer, label: 'Employer' },
  { value: Portal.HealthCareContractor, label: 'Health Care Contractor' },
  { value: Portal.InsuranceCarrier, label: 'Insurance Carrier' },
  { value: Portal.PhysicianInd, label: 'Physician (Ind)' },
  { value: Portal.RepresentativeInd, label: 'Representative (Ind)' },
  { value: Portal.AgentOrg, label: 'Agent (Org)' },
];

export enum SignUpFormKey {
  ProgramType = 'programTypeIds',
  UserClassification = 'portalUserClassificationId',
  Portal = 'portalUserTypeId',
  UserType = 'portalUserTypeId',
  Email = 'email',
  Password = 'password', // pragma: allowlist secret
  ConfirmPassword = 'confirmPassword', // pragma: allowlist secret
  FirstName = 'firstName',
  LastName = 'lastName',
  DateOfBirth = 'dob',
  AdminServices = 'adminServices',
  AdminServicesName = 'adminServicesName',
  AdjusterID = 'adjusterId',
  HealthCareContractorName = 'healthCareContractorName',
  InsuranceCarrierName = 'insuranceCarrierName',
  PhysicianName = 'physicianName',
  Address = 'address',
  State = 'state',
  City = 'city',
  ZipCode = 'zipCode',
  MiddleInitial = 'middleInitial',
  PhoneNumber = 'phoneNumber',
  RelationToClaimant = 'relationToClaimant',
  MiddleName = 'middleName',
  IdentificationType = 'identificationType',
  IdentificationNumber = 'identificationNumber',
  MailingAddress = 'mailingAddress',
  MailingStreet = 'mailingStreet',
  MailingState = 'mailingState',
  MailingCity = 'mailingCity',
  MailingZipCode = 'mailingZipCode',
  ParentCompanyName = 'nameOfParentCompany',
  NatureOfBusiness = 'natureOfBusiness',
  OrganizationType = 'organizationType',
  FederalIDNumber = 'federalIdNumber',
  ContactNumber = 'contactNumber',
  OrganizationContactNumber = 'organizationContactNumber',
  AdjustingServices = 'adjustingServices',
  AdjustingServicesName = 'adjustingServicesName',
  ServiceType = 'serviceType',
  IslandOfService = 'islandOfService',
  RegistrationExpirationDate = 'registrationExpirationDate',
  HealthCareContractor = 'healthCareContractor',
  AgencyName = 'agencyName',
  EmployerAddress = 'employerAddress',
  EmployerCity = 'employerCity',
  EmployerZipCode = 'employerZipCode',
  EOFMailingAddress = 'eofMailingAddress',
  EOFMailingStreet = 'eofMailingStreet',
  EOFMailingCity = 'eofMailingCity',
  EOFMailingState = 'eofMailingState',
  EOFMailingZipCode = 'eofMailingZipCode',
  EOFAddress = 'eofAddress',
  EOFStreet = 'eofStreet',
  EOFCity = 'eofCity',
  EOFState = 'eofState',
  EOFZipCode = 'eofZipCode',
  EOFPhoneNumber = 'eofPhoneNumber',
  EOFName = 'eofName',

  DSFirstName = 'dsFirstName',
  DSLastName = 'dsLastName',
  DSMiddleName = 'dsMiddleName',
  DSBirthDay = 'dsBirthDate',
  DSPhoneNumber = 'dsPhoneNumber',
  DSEmailAddress = 'dsEmailAddress',
  DSIDNumber = 'dsIdNumber',
  IsAgreement = 'isAgree',
  ClaimantIDType = 'claimantIdType',
  ClaimantIDNumber = 'claimantIdNumber',

  IsThirdPartyAdmin = 'isThirdPartyAdmin',
  ThirdPartyAdminType = 'thirdPartyAdminType',
  Username = 'username',

  OrganizationInfo = 'orgInfo',
  ClaimantEmail = 'claimantEmail',
  EmployerName = 'employerName',
}

export type SignupFormValues = {
  [SignUpFormKey.ProgramType]: ProgramType[];
  [SignUpFormKey.UserClassification]: UserClassification;
  [SignUpFormKey.UserType]: UserType;
  [SignUpFormKey.Username]: string;

  [SignUpFormKey.Email]: string;
  [SignUpFormKey.Password]: string;
  [SignUpFormKey.ConfirmPassword]: string;

  [SignUpFormKey.LastName]: string;
  [SignUpFormKey.FirstName]: string;
  [SignUpFormKey.DateOfBirth]?: string | Date;

  [SignUpFormKey.AdjustingServicesName]?: string;
  [SignUpFormKey.AdminServices]: string;
  [SignUpFormKey.AdminServicesName]: string;
  [SignUpFormKey.AdjusterID]?: string; //adjuster
  [SignUpFormKey.EmployerName]?: string; //eof
  [SignUpFormKey.EOFZipCode]?: string; //eof
  [SignUpFormKey.EOFState]?: string; //eof
  [SignUpFormKey.EOFCity]?: string; //eof
  [SignUpFormKey.EOFAddress]?: string; //employer
  [SignUpFormKey.HealthCareContractorName]?: string; //healthcare contractor
  [SignUpFormKey.InsuranceCarrierName]?: string; //insurance carrier
  [SignUpFormKey.PhysicianName]?: string; //physician org

  [SignUpFormKey.Address]?: string;
  [SignUpFormKey.State]?: string;
  [SignUpFormKey.City]?: string;
  [SignUpFormKey.ZipCode]?: string;
  [SignUpFormKey.PhoneNumber]: string;
  [SignUpFormKey.RelationToClaimant]?: string;
  [SignUpFormKey.IdentificationType]: IdentificationType;
  [SignUpFormKey.IdentificationNumber]: string;
  [SignUpFormKey.MiddleName]?: string;
  [SignUpFormKey.ParentCompanyName]?: string;
  [SignUpFormKey.NatureOfBusiness]?: string;
  [SignUpFormKey.OrganizationType]?: OrganizationType;
  [SignUpFormKey.ContactNumber]: string;
  [SignUpFormKey.OrganizationContactNumber]: string;
  [SignUpFormKey.AdjustingServices]?: AdjustingService;
  [SignUpFormKey.FederalIDNumber]: string;
  [SignUpFormKey.ServiceType]?: ServiceType | OtherServiceType | LegalServiceType;
  [SignUpFormKey.IslandOfService]?: IslandOfService[];
  [SignUpFormKey.RegistrationExpirationDate]?: string | Date;
  [SignUpFormKey.AgencyName]?: string;
  [SignUpFormKey.HealthCareContractor]?: string;
  [SignUpFormKey.EOFStreet]?: string;
  [SignUpFormKey.EOFPhoneNumber]?: string;
  [SignUpFormKey.EOFMailingAddress]?: string;
  [SignUpFormKey.EOFMailingStreet]?: string;
  [SignUpFormKey.EOFMailingZipCode]?: string; //eof
  [SignUpFormKey.EOFMailingState]?: string; //eof
  [SignUpFormKey.EOFMailingCity]?: string; //eof

  [SignUpFormKey.DSFirstName]?: string;
  [SignUpFormKey.DSLastName]?: string;
  [SignUpFormKey.DSMiddleName]?: string;
  [SignUpFormKey.DSBirthDay]?: string | Date;
  [SignUpFormKey.DSPhoneNumber]?: string;
  [SignUpFormKey.DSEmailAddress]?: string;
  [SignUpFormKey.DSIDNumber]?: string;
  [SignUpFormKey.IsAgreement]: boolean;

  [SignUpFormKey.ThirdPartyAdminType]?: string;
  [SignUpFormKey.IsThirdPartyAdmin]?: boolean;
  [SignUpFormKey.MailingAddress]?: string;
  [SignUpFormKey.MailingState]?: string;
  [SignUpFormKey.MailingCity]?: string;
  [SignUpFormKey.MailingZipCode]?: string;

  [SignUpFormKey.EmployerAddress]?: string;
  [SignUpFormKey.EmployerCity]?: string;
  [SignUpFormKey.EmployerZipCode]?: string;
  [SignUpFormKey.ClaimantIDType]?: IdentificationType;
  [SignUpFormKey.ClaimantEmail]?: string;
  [SignUpFormKey.ClaimantIDNumber]?: string;
};

export const initialSignupFormValues: SignupFormValues = {
  [SignUpFormKey.ProgramType]: [],
  [SignUpFormKey.UserType]: null,
  [SignUpFormKey.Email]: '',
  [SignUpFormKey.Username]: '',
  [SignUpFormKey.Password]: '',
  [SignUpFormKey.ConfirmPassword]: '',
  [SignUpFormKey.LastName]: '',
  [SignUpFormKey.FirstName]: '',

  [SignUpFormKey.AdjustingServicesName]: '', //adjuster
  [SignUpFormKey.AdjusterID]: '', //adjuster
  [SignUpFormKey.HealthCareContractorName]: '', //healthcare contractor

  [SignUpFormKey.InsuranceCarrierName]: '', //insurance carrier
  [SignUpFormKey.PhysicianName]: '', //physician org

  [SignUpFormKey.Address]: '',
  [SignUpFormKey.State]: '',
  [SignUpFormKey.City]: '',
  [SignUpFormKey.ZipCode]: '',
  [SignUpFormKey.PhoneNumber]: '',
  [SignUpFormKey.RelationToClaimant]: '',
  [SignUpFormKey.IdentificationType]: null,
  [SignUpFormKey.IdentificationNumber]: '',
  [SignUpFormKey.MailingAddress]: '',
  [SignUpFormKey.MailingState]: '',
  [SignUpFormKey.MailingCity]: '',
  [SignUpFormKey.MailingZipCode]: '',
  [SignUpFormKey.EmployerAddress]: '',
  [SignUpFormKey.EmployerCity]: '',
  [SignUpFormKey.EmployerZipCode]: '',
  [SignUpFormKey.MiddleName]: '',
  [SignUpFormKey.ParentCompanyName]: '',
  [SignUpFormKey.NatureOfBusiness]: '',
  [SignUpFormKey.OrganizationType]: null,
  [SignUpFormKey.ContactNumber]: '',
  [SignUpFormKey.OrganizationContactNumber]: '',
  [SignUpFormKey.FederalIDNumber]: '',
  [SignUpFormKey.AdjustingServices]: null,
  [SignUpFormKey.ServiceType]: null,
  [SignUpFormKey.IslandOfService]: [],
  [SignUpFormKey.UserClassification]: null,
  [SignUpFormKey.AgencyName]: '',
  [SignUpFormKey.HealthCareContractor]: '',
  [SignUpFormKey.EOFStreet]: '',
  [SignUpFormKey.EOFMailingAddress]: '',
  [SignUpFormKey.EmployerName]: '',
  [SignUpFormKey.EOFZipCode]: '',
  [SignUpFormKey.EOFState]: '',
  [SignUpFormKey.EOFCity]: '',
  [SignUpFormKey.EOFAddress]: '',
  [SignUpFormKey.EOFPhoneNumber]: '',
  [SignUpFormKey.DateOfBirth]: null,
  [SignUpFormKey.RegistrationExpirationDate]: null,
  [SignUpFormKey.AdminServices]: '',
  [SignUpFormKey.AdminServicesName]: '',

  [SignUpFormKey.DSFirstName]: '',

  [SignUpFormKey.DSLastName]: '',

  [SignUpFormKey.DSMiddleName]: '',

  [SignUpFormKey.DSBirthDay]: null,
  [SignUpFormKey.DSPhoneNumber]: '',
  [SignUpFormKey.DSEmailAddress]: '',
  [SignUpFormKey.DSIDNumber]: '',
  [SignUpFormKey.IsAgreement]: false,

  [SignUpFormKey.ClaimantIDType]: null,
  [SignUpFormKey.ClaimantIDNumber]: '',
  [SignUpFormKey.ThirdPartyAdminType]: null,
  [SignUpFormKey.IsThirdPartyAdmin]: false,
};

const wrapIsDependentOrSpouseSchema = (schema: SchemaOf<any>) =>
  Yup.mixed().when(SignUpFormKey.UserType, {
    is: (userType: UserType) => userType === UserType.Spouse || userType === UserType.Dependent,
    then: schema,
  });

const wrapIsDependentOrSpouseOrLegalGuardianSchema = (schema: SchemaOf<any>) =>
  Yup.mixed().when(SignUpFormKey.UserType, {
    is: (userType: UserType) =>
      userType === UserType.Spouse ||
      userType === UserType.Dependent ||
      userType === UserType.LegalGuardian,
    then: schema,
  });

const wrapIsClaimantSchema = (schema: SchemaOf<any>) =>
  Yup.mixed().when(SignUpFormKey.UserType, {
    is: (userType: UserType) =>
      userType === UserType.Spouse ||
      userType === UserType.Dependent ||
      userType === UserType.Employee,
    then: schema,
  });

const wrapIsClaimantOrLegalGuardianSchema = (schema: SchemaOf<any>) =>
  Yup.mixed().when(SignUpFormKey.UserType, {
    is: (userType: UserType) =>
      userType === UserType.Spouse ||
      userType === UserType.Dependent ||
      userType === UserType.Employee ||
      userType === UserType.LegalGuardian,
    then: schema,
  });

const wrapIsSelfInsuredOrEmployer = (schema: SchemaOf<any>) =>
  Yup.mixed().when([SignUpFormKey.UserType, SignUpFormKey.UserClassification], {
    is: (userType: UserType, userClassification: UserClassification) =>
      userType === UserType.SelfInsured || userClassification === UserClassification.Employer,
    then: schema,
  });

const wrapEOFCity = (schema: SchemaOf<any>) =>
  Yup.string()
    .nullable()
    .when([SignUpFormKey.UserType, SignUpFormKey.UserClassification], {
      is: (userType: UserType, userClassification: UserClassification) =>
        userType === UserType.Employee ||
        userType === UserType.Spouse ||
        userType === UserType.Dependent ||
        userClassification === UserClassification.Employer ||
        userType === UserType.Carrier ||
        userType === UserType.Adjuster ||
        userType === UserType.ThirdPartyAdmin ||
        userType === UserType.SelfInsured ||
        userType === UserType.Contractor ||
        userType === UserType.BillReview ||
        userType === UserType.VocationalRehabilitationCounselor ||
        userClassification === UserClassification.Legal,
      then: schema,
    });

export const signupFormValidationSchema = Yup.object().shape({
  [SignUpFormKey.ProgramType]: Yup.array().min(1, 'Program Type is required.'),
  [SignUpFormKey.UserClassification]: Yup.string()
    .nullable()
    .required('User Classification is required.'),
  [SignUpFormKey.Password]: Yup.string().nullable().required('Password is required.'),
  [SignUpFormKey.ConfirmPassword]: Yup.string().nullable().required('Password is required.'),
  [SignUpFormKey.UserType]: Yup.string().nullable().required('User Type is required'),
  [SignUpFormKey.Email]: Yup.string()
    .nullable()
    .when(SignUpFormKey.UserType, {
      is: (userType: UserType) => userType !== UserType.Spouse && userType !== UserType.Dependent,
      then: Yup.string().email('Please enter a valid email address').required('Email is required'),
    }),
  [SignUpFormKey.LastName]: Yup.string().nullable().required('Last Name is required.'),
  [SignUpFormKey.FirstName]: Yup.string().nullable().required('First Name is required.'),
  [SignUpFormKey.DateOfBirth]: wrapIsClaimantSchema(
    Yup.date().required('Date of Birth is required.').nullable()
  ),

  [SignUpFormKey.AdjustingServicesName]: Yup.string()
    .nullable()
    .when(SignUpFormKey.UserType, {
      is: UserType.Adjuster,
      then: Yup.string().nullable().required('Adjusting Services Name is required.'),
    }),
  [SignUpFormKey.AdminServicesName]: Yup.string()
    .nullable()
    .when(SignUpFormKey.UserType, {
      is: UserType.ThirdPartyAdmin,
      then: Yup.string().nullable().required('Administrator Services Name is required.'),
    }),
  [SignUpFormKey.AdminServices]: Yup.string()
    .nullable()
    .when(SignUpFormKey.UserType, {
      is: UserType.ThirdPartyAdmin,
      then: Yup.string().nullable().required('Administrator Services is required.'),
    }),
  [SignUpFormKey.AdjusterID]: Yup.string()
    .nullable()
    .when(SignUpFormKey.UserType, {
      is: UserType.Adjuster,
      then: Yup.string()
        .required('Adjuster ID is required.')
        .numberOnly()
        .min(4, 'Please enter a 4-digit Adjuster ID'),
    }),
  [SignUpFormKey.HealthCareContractorName]: Yup.string()
    .nullable()
    .when(SignUpFormKey.UserType, {
      is: UserType.Contractor,
      then: Yup.string().nullable().required('Health Care Contractor Name is required.'),
    }),
  [SignUpFormKey.InsuranceCarrierName]: Yup.string()
    .nullable()
    .when(SignUpFormKey.UserType, {
      is: UserType.Carrier,
      then: Yup.string().nullable().required('Insurance Carrier Name is required.'),
    }),
  [SignUpFormKey.PhysicianName]: Yup.string()
    .nullable()
    .when(SignUpFormKey.UserClassification, {
      is: UserClassification.HealthCareProvider,
      then: Yup.string().nullable().required('Physician Name is required.'),
    }),
  [SignUpFormKey.Address]: wrapIsClaimantOrLegalGuardianSchema(
    Yup.string().nullable().required('Address is required')
  ),
  [SignUpFormKey.State]: wrapIsClaimantOrLegalGuardianSchema(
    Yup.string()
      .required('State is required')
      .letterOnly()
      .length(2, 'Please enter a 2-letter State')
  ),
  [SignUpFormKey.MailingState]: wrapIsClaimantOrLegalGuardianSchema(
    Yup.string().letterOnly().length(2, 'Please enter a 2-letter State')
  ),
  [SignUpFormKey.City]: wrapIsClaimantOrLegalGuardianSchema(Yup.string().nullable().required()),
  [SignUpFormKey.ZipCode]: wrapIsClaimantOrLegalGuardianSchema(
    Yup.string()
      .required()
      .min(5, 'Invalid format. Please input valid digits of zip code.')
      .max(9, 'Invalid format. Please input valid digits of zip code.')
      .numberOnly('Invalid format. Please input valid digits of zip code.')
  ),
  [SignUpFormKey.MailingZipCode]: Yup.string()
    .nullable()
    .min(5, 'Invalid format. Please input valid digits of zip code.')
    .max(9, 'Invalid format. Please input valid digits of zip code.')
    .numberOnly('Invalid format. Please input valid digits of zip code.'),

  [SignUpFormKey.PhoneNumber]: wrapIsClaimantSchema(Yup.string().phone().required()),
  [SignUpFormKey.IdentificationType]: wrapIsClaimantOrLegalGuardianSchema(
    Yup.string().nullable().required()
  ),
  [SignUpFormKey.RelationToClaimant]: wrapIsDependentOrSpouseSchema(
    Yup.string().nullable().required()
  ),

  [SignUpFormKey.DSFirstName]: wrapIsDependentOrSpouseOrLegalGuardianSchema(
    Yup.string().nullable().required()
  ),
  [SignUpFormKey.DSLastName]: wrapIsDependentOrSpouseOrLegalGuardianSchema(
    Yup.string().nullable().required()
  ),
  [SignUpFormKey.DSBirthDay]: wrapIsDependentOrSpouseOrLegalGuardianSchema(
    Yup.date().required().nullable()
  ),
  [SignUpFormKey.DSPhoneNumber]: wrapIsDependentOrSpouseOrLegalGuardianSchema(
    Yup.string().phone().required('Phone Number is required')
  ),
  [SignUpFormKey.DSEmailAddress]: wrapIsDependentOrSpouseOrLegalGuardianSchema(
    Yup.string().email('Please enter a valid email address').required()
  ),
  [SignUpFormKey.DSIDNumber]: wrapIsDependentOrSpouseSchema(
    Yup.string().nullable().required().length(11, 'Please enter a 9-digit Social Security Number')
  ),
  [SignUpFormKey.IdentificationNumber]: wrapIsClaimantOrLegalGuardianSchema(
    Yup.string()
      .required()
      .when(SignUpFormKey.IdentificationType, {
        is: IdentificationType.SSN,
        then: Yup.string().length(9, 'Please enter a 9-digit Identification Number'),
        otherwise: Yup.string()
          .bothLetterAndNumber('Please enter a 11-alphanumeric identification number')
          .length(11, 'Please enter a 11-alphanumeric identification number'),
      })
  ),
  [SignUpFormKey.NatureOfBusiness]: wrapIsSelfInsuredOrEmployer(
    Yup.string().nullable().required('Nature of Business is required.')
  ),
  [SignUpFormKey.OrganizationType]: wrapIsSelfInsuredOrEmployer(
    Yup.string().nullable().required('Organization type is required.')
  ),
  [SignUpFormKey.Username]: Yup.string().nullable().required('Username is required.'),
  [SignUpFormKey.FederalIDNumber]: Yup.string()
    .nullable()
    .when([SignUpFormKey.UserType, SignUpFormKey.UserClassification], {
      is: (userType: UserType, userClassification: UserClassification) =>
        userType === UserType.Contractor ||
        userClassification === UserClassification.Employer ||
        userClassification === UserClassification.Insurance ||
        userClassification === UserClassification.HealthCareProvider,
      then: Yup.string()
        .nullable()
        .required('Federal ID Number is required.')
        .min(10, 'Please enter a 9-digit Federal ID Number'),
    }),
  [SignUpFormKey.ContactNumber]: Yup.string()
    .nullable()
    .when([SignUpFormKey.UserType, SignUpFormKey.UserClassification], {
      is: (userType: UserType, userClassification: UserClassification) =>
        !(
          userType === UserType.Employee ||
          userType === UserType.Dependent ||
          userType === UserType.Spouse ||
          userType === UserType.SelfInsured ||
          userType === UserType.Contractor ||
          userClassification === UserClassification.Employer
        ),
      then: Yup.string().phone().required('Contact Number is required'),
    }),
  [SignUpFormKey.OrganizationContactNumber]: Yup.string()
    .nullable()
    .when(SignUpFormKey.UserType, {
      is: (userType: UserType, userClassification: UserClassification) =>
        userType === UserType.Carrier ||
        userType === UserType.Adjuster ||
        userType === UserType.ThirdPartyAdmin ||
        userType === UserType.Contractor ||
        userClassification === UserClassification.Employer ||
        userType === UserType.SelfInsured,

      then: Yup.string().phone().required('Organization Contact Number is required'),
    })
    .when(SignUpFormKey.UserClassification, {
      is: UserClassification.HealthCareProvider,
      then: Yup.string().phone().required('Office Phone Number is required'),
    }),
  [SignUpFormKey.AdjustingServices]: Yup.string()
    .nullable()
    .when(SignUpFormKey.UserType, {
      is: UserType.Adjuster,
      then: Yup.string().nullable().required('Services is required'),
    }),
  [SignUpFormKey.ServiceType]: Yup.string()
    .nullable()
    .when([SignUpFormKey.UserClassification, SignUpFormKey.UserType], {
      is: (userClassification: UserClassification, userType: UserType) =>
        userClassification === UserClassification.HealthCareProvider ||
        userType === UserType.BillReview ||
        userType === UserType.VocationalRehabilitationCounselor ||
        userClassification === UserClassification.GovernmentAgency ||
        userClassification === UserClassification.Legal,
      then: Yup.string().nullable().required('Type of Service is required'),
    }),
  [SignUpFormKey.IslandOfService]: Yup.array()
    .nullable()
    .when([SignUpFormKey.UserClassification, SignUpFormKey.UserType], {
      is: (userClassification: UserClassification, userType: UserType) =>
        userType === UserType.BillReview ||
        userType === UserType.VocationalRehabilitationCounselor ||
        userClassification === UserClassification.Legal,
      then: Yup.array().min(1, 'Island(s) of Service/Practice is required.'),
    }),
  [SignUpFormKey.RegistrationExpirationDate]: Yup.date()
    .nullable()
    .when([SignUpFormKey.UserClassification, SignUpFormKey.ServiceType], {
      is: (userClassification: UserClassification, serviceType: LegalServiceType) =>
        serviceType === LegalServiceType.VOCATIONAL_REHABILITATION_COUNSELOR &&
        (userClassification === UserClassification.Representative ||
          userClassification === UserClassification.Legal),
      then: Yup.date().required('Registration Expiration Date is required').nullable(),
    }),
  [SignUpFormKey.AgencyName]: Yup.string()
    .nullable()
    .when(SignUpFormKey.UserClassification, {
      is: UserClassification.GovernmentAgency,
      then: Yup.string().nullable().required('Agency Name is required'),
    }),
  [SignUpFormKey.EOFStreet]: Yup.string()
    .nullable()
    .when(SignUpFormKey.UserClassification, {
      is: UserClassification.GovernmentAgency,
      then: Yup.string().nullable().required('Street is required'),
    }),
  [SignUpFormKey.HealthCareContractor]: Yup.string()
    .nullable()
    .when(SignUpFormKey.UserType, {
      is: UserClassification.Contractor,
      then: Yup.string()
        .required('Health Care Contractor ID is required')
        .numberOnly()
        .length(4, 'Please enter a 4-digit Health Care Contractor ID'),
    }),
  [SignUpFormKey.EOFAddress]: Yup.string()
    .nullable()
    .when([SignUpFormKey.UserType, SignUpFormKey.UserClassification], {
      is: (userType: UserType, userClassification: UserClassification) =>
        userType === UserType.Employee ||
        userType === UserType.Spouse ||
        userType === UserType.Dependent ||
        userClassification === UserClassification.Employer ||
        userType === UserType.Carrier ||
        userType === UserType.Adjuster ||
        userType === UserType.ThirdPartyAdmin ||
        userType === UserType.SelfInsured ||
        userType === UserType.Contractor ||
        userClassification === UserClassification.HealthCareProvider ||
        userType === UserType.BillReview ||
        userType === UserType.VocationalRehabilitationCounselor ||
        userClassification === UserClassification.Legal,
      then: Yup.string().nullable().required('Address is required'),
    })
    .when(SignUpFormKey.UserClassification, {
      is: UserClassification.HealthCareProvider,
      then: Yup.string().nullable().required('Office Address is required'),
    }),
  [SignUpFormKey.EOFCity]: wrapEOFCity(Yup.string().nullable().required('City is required')),
  [SignUpFormKey.EOFState]: wrapEOFCity(
    Yup.string()
      .required('State is required')
      .letterOnly()
      .length(2, 'Please enter a 2-letter State')
  ),
  [SignUpFormKey.EOFZipCode]: Yup.string()
    .nullable()
    .when([SignUpFormKey.UserType, SignUpFormKey.UserClassification], {
      is: (userType: UserType, userClassification: UserClassification) =>
        userType === UserType.Employee ||
        userType === UserType.Spouse ||
        userType === UserType.Dependent ||
        userClassification === UserClassification.Employer ||
        userType === UserType.Carrier ||
        userType === UserType.Adjuster ||
        userType === UserType.ThirdPartyAdmin ||
        userType === UserType.SelfInsured ||
        userType === UserType.BillReview ||
        userType === UserType.VocationalRehabilitationCounselor ||
        userClassification === UserClassification.Legal ||
        userType === UserType.Contractor ||
        userClassification === UserClassification.GovernmentAgency,
      then: Yup.string()
        .required('Zip Code is required')
        .min(5, 'Invalid format. Please input valid digits of zip code.')
        .max(9, 'Invalid format. Please input valid digits of zip code.')
        .numberOnly('Invalid format. Please input valid digits of zip code.'),
    }),
  [SignUpFormKey.EmployerName]: Yup.string()
    .nullable()
    .when([SignUpFormKey.UserType, SignUpFormKey.UserClassification], {
      is: (userType: UserType, userClassification: UserClassification) =>
        userType === UserType.Employee ||
        userType === UserType.Spouse ||
        userType === UserType.Dependent ||
        userClassification === UserClassification.Employer ||
        userType === UserType.SelfInsured ||
        userType === UserType.BillReview ||
        userType === UserType.VocationalRehabilitationCounselor ||
        userClassification === UserClassification.Legal,
      then: Yup.string().nullable().required('Name is required'),
    }),
  [SignUpFormKey.EOFMailingState]: Yup.string()
    .nullable()
    .when([SignUpFormKey.UserType, SignUpFormKey.UserClassification], {
      is: (userType: UserType, userClassification: UserClassification) =>
        userClassification === UserClassification.Employer || userType === UserType.SelfInsured,
      then: Yup.string().nullable().letterOnly().length(2, 'Please enter a 2-letter State'),
    }),
  [SignUpFormKey.EOFMailingZipCode]: Yup.string()
    .nullable()
    .min(5, 'Invalid format. Please input valid digits of zip code.')
    .max(9, 'Invalid format. Please input valid digits of zip code.')
    .numberOnly('Invalid format. Please input valid digits of zip code.'),

  [SignUpFormKey.ClaimantIDNumber]: Yup.string()
    .nullable()
    .when(SignUpFormKey.UserType, {
      is: UserType.LegalGuardian,
      then: Yup.string()
        .required('Identification Number is required')
        .when(SignUpFormKey.ClaimantIDType, {
          is: IdentificationType.SSN,
          then: Yup.string().length(9, 'Please enter a 9-digit Identification Number'),
          otherwise: Yup.string()
            .bothLetterAndNumber('Please enter a 11-alphanumeric identification number')
            .length(11, 'Please enter a 11-alphanumeric identification number'),
        }),
    }),
  [SignUpFormKey.ClaimantIDType]: Yup.string()
    .nullable()
    .when(SignUpFormKey.UserType, {
      is: UserType.LegalGuardian,
      then: Yup.string().nullable().required('Identification Type is required'),
    }),

  [SignUpFormKey.ThirdPartyAdminType]: Yup.string()
    .nullable()
    .when([SignUpFormKey.UserType, SignUpFormKey.IsThirdPartyAdmin], {
      is: (userType: UserType, isTPA: boolean) => userType === UserType.Adjuster && isTPA,
      then: Yup.string().nullable().required('Complete this field.'),
    }),
});

export const USERNAME_REGEX = /[^a-z0-9]/g;
