import React, { ReactNode } from 'react';
import { IMAGES } from 'src/appConfig/images';
import { Image, View } from 'src/components/common';
import Header from '../Header';

import './styles.scss';

const Body: React.FC<Props> = ({
  children,
  bodyWidth = '400px',
  title = '',
  subTitle = '',
  renderIf = true,
}) => {
  if (!renderIf) return null;

  return (
    <View className="ctn-uam" flexGrow={1}>
      <Image className="ctn-uam__image" src={IMAGES.backgroundUAM} alt="" />
      <View className="ctn-uam__container--wrap" flexGrow={1}>
        <View className="ctn-uam__container" flexGrow={1}>
          <Header title={title} subTitle={subTitle} />
          {children}
        </View>
      </View>
    </View>
  );
};

type Props = {
  children: React.ReactNode;
  bodyWidth?: string;
  title?: string | ReactNode;
  subTitle?: string;
  renderIf?: boolean;
};

export default Body;
