import { useFormikContext } from 'formik';
import React from 'react';
import { DatePicker, Grid, Input, InputMask, InputPhone, Select } from 'src/components/common';
import ClaimantAddress from '../../EmployeeQuestion/ClaimantAddress';
import {
  IdentificationType,
  SignUpFormKey,
  SignupFormValues,
  identificationTypeOptions,
} from '../../helpers';
import SectionText from '../../SectionText';
import { useSignUp } from '../../SignUpProvider';

const ClaimantInformation: React.FC<Props> = () => {
  const { getFieldProps, setFieldValue, values, setFieldTouched } =
    useFormikContext<SignupFormValues>();

  const { getErrorMessage } = useSignUp();

  const handleIdentificationTypeChange = (name, value) => {
    setFieldValue(name, value);
    setFieldValue(SignUpFormKey.ClaimantIDNumber, '');
    setFieldTouched(SignUpFormKey.ClaimantIDNumber, false);
  };

  return (
    <>
      <SectionText section="Claimant's Personal Information" />
      <Grid.Item>
        <Input
          label={'Last Name'}
          required
          placeholder="Enter your last name"
          errorMessage={getErrorMessage(SignUpFormKey.DSLastName)}
          maxLength={40}
          {...getFieldProps(SignUpFormKey.DSLastName)}
        />
      </Grid.Item>
      <Grid.Item>
        <Input
          label={`Middle Name`}
          placeholder="Enter your middle name"
          errorMessage={getErrorMessage(SignUpFormKey.DSMiddleName)}
          {...getFieldProps(SignUpFormKey.DSMiddleName)}
        />
      </Grid.Item>
      <Grid.Item>
        <Input
          label={'First Name'}
          required
          placeholder="Enter your first name"
          errorMessage={getErrorMessage(SignUpFormKey.DSFirstName)}
          maxLength={30}
          {...getFieldProps(SignUpFormKey.DSFirstName)}
        />
      </Grid.Item>
      <Grid.Item>
        <DatePicker
          label={`Date of Birth`}
          errorMessage={getErrorMessage(SignUpFormKey.DSBirthDay)}
          {...getFieldProps(SignUpFormKey.DSBirthDay)}
          selected={values?.[SignUpFormKey.DSBirthDay] as Date}
          onChange={setFieldValue}
          placeholder={'MM/DD/YYYY'}
          maxDate={new Date()}
          required
        />
      </Grid.Item>
      <Grid.Item>
        <Select
          label={'Identification Type'}
          options={identificationTypeOptions}
          errorMessage={getErrorMessage(SignUpFormKey.ClaimantIDType)}
          {...getFieldProps(SignUpFormKey.ClaimantIDType)}
          onChange={handleIdentificationTypeChange}
          isClearable={false}
          required
          onBlur={setFieldTouched}
        />
      </Grid.Item>
      <Grid.Item>
        <InputMask
          label={`Identification Number`}
          mask={
            values[SignUpFormKey.ClaimantIDType] === IdentificationType.SSN
              ? '999999999'
              : '***********'
          }
          placeholder="Enter your Identification Number"
          required
          disabled={!values[SignUpFormKey.ClaimantIDType]}
          errorMessage={getErrorMessage(SignUpFormKey.ClaimantIDNumber)}
          {...getFieldProps(SignUpFormKey.ClaimantIDNumber)}
        />
      </Grid.Item>
      <Grid.Item>
        <InputPhone
          international={false}
          countries={['US']}
          label={`Phone Number`}
          placeholder={'Enter your phone number'}
          {...getFieldProps(SignUpFormKey.DSPhoneNumber)}
          errorMessage={getErrorMessage(SignUpFormKey.DSPhoneNumber)}
          onChange={setFieldValue}
          required
        />
      </Grid.Item>
      <Grid.Item variant="is-full">
        <Input
          label={'Email Address'}
          required
          placeholder={`Enter Email Address`}
          {...getFieldProps(SignUpFormKey.DSEmailAddress)}
          errorMessage={getErrorMessage(SignUpFormKey.DSEmailAddress)}
          containerClassName="cmp-email-container"
        />
      </Grid.Item>
      <ClaimantAddress />
    </>
  );
};

type Props = {};

export default ClaimantInformation;
