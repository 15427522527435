import { AlertTitle } from '@mui/material';
import AlertMUI, { AlertProps } from '@mui/material/Alert';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import cn from 'classnames';
import React from 'react';
import './styles.scss';
import { COLOR_CODE } from 'src/appConfig/constants';
import { ErrorOutlineOutlined } from '@material-ui/icons';

const Alert: React.FC<Props> = ({
  severity,
  title,
  variant,
  children,
  containerClassName,
  alertTitleStyle = { fontSize: '16px', fontWeight: '500' },
  ...props
}) => {
  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiAlert: {
          styleOverrides: {
            standardSuccess: {
              backgroundColor: COLOR_CODE.SUCCESS_ALERT,
              color: COLOR_CODE.PRIMARY_DARK,
            },
            standardError: {
              backgroundColor: COLOR_CODE.ERROR_ALERT,
            },
            standardWarning: {
              backgroundColor: COLOR_CODE.WARNING_ALERT,
              color: COLOR_CODE.PRIMARY_DARK,
            },
            standardInfo: {
              backgroundColor: COLOR_CODE.INFO_ALERT,
              color: COLOR_CODE.PRIMARY_DARK,
            },
          },
        },
      },
    });

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <AlertMUI
        severity={severity}
        variant={variant}
        className={cn(containerClassName, 'cmp-alert')}
        iconMapping={{
          info: <ErrorOutlineOutlined className={cn(containerClassName, 'cpm-alert-info-icon')} />,
        }}
        {...props}
      >
        {title && <AlertTitle style={alertTitleStyle}>{title}</AlertTitle>}
        {children}
      </AlertMUI>
    </ThemeProvider>
  );
};

type Props = {
  severity?: 'success' | 'error' | 'warning' | 'info';
  variant?: 'standard' | 'filled' | 'outlined';
  containerClassName?: string;
  children: React.ReactNode;
  title?: string;
  alertTitleStyle?: React.CSSProperties;
} & AlertProps;

export default Alert;
