/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { IconSuccess, Link, Text, View } from 'src/components/common';
import { UAMBody } from '../common';
import { Navigator } from 'src/services';
import { PATHS } from 'src/appConfig/paths';

const AccountCreatedSuccess: React.FC<Props> = () => {
  return (
    <UAMBody>
      <View justify="center" align="center" isRowWrap>
        <IconSuccess size={36} />
        <Text className="ml-12" size={32}>
          Account Created!
        </Text>
      </View>
      <Text className="text-center pt-12 has-text-grey-dark">
        Your account has been successfully created. However, it still requires approval and access
        to the DLIR DCD Portal.
      </Text>
      <View align="center" className="pt-32">
        <Link
          onClick={() => {
            Navigator.navigate(PATHS.signIn);
          }}
        >
          Back to Login
        </Link>
      </View>
    </UAMBody>
  );
};

type Props = {};

export default AccountCreatedSuccess;
