import { useFormikContext } from 'formik';
import React from 'react';
import { Grid, Input } from 'src/components/common';
import { SignUpFormKey, SignupFormValues } from '../../helpers';
import { useSignUp } from '../../SignUpProvider';

const MailingAddressSection: React.FC<Props> = () => {
  const { getFieldProps } = useFormikContext<SignupFormValues>();

  const { getErrorMessage, handleChangeState } = useSignUp();

  return (
    <>
      <Grid.Item>
        <Input
          label={`Mailing Address, if different`}
          placeholder={`Enter your mailing address`}
          errorMessage={getErrorMessage(SignUpFormKey.MailingAddress)}
          {...getFieldProps(SignUpFormKey.MailingAddress)}
        />
      </Grid.Item>
      <Grid.Item>
        <Input
          label={`Mailing City`}
          placeholder={`Enter your mailing city`}
          errorMessage={getErrorMessage(SignUpFormKey.MailingCity)}
          {...getFieldProps(SignUpFormKey.MailingCity)}
        />
      </Grid.Item>
      <Grid.Item>
        <Input
          label={`Mailing State`}
          placeholder={`Enter your mailing state`}
          errorMessage={getErrorMessage(SignUpFormKey.MailingState)}
          {...getFieldProps(SignUpFormKey.MailingState)}
          onChange={handleChangeState}
        />
      </Grid.Item>
      <Grid.Item>
        <Input
          label={`Mailing Zip Code`}
          errorMessage={getErrorMessage(SignUpFormKey.MailingZipCode)}
          placeholder={`Enter your mailing zip code`}
          {...getFieldProps(SignUpFormKey.MailingZipCode)}
        />
      </Grid.Item>
    </>
  );
};

type Props = {};

export default MailingAddressSection;
