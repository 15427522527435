import { FormikProps, useFormik } from 'formik';
import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PATHS } from 'src/appConfig/paths';
import { Button, Form, Grid, Input, InputPassword, Link, Text, View } from 'src/components/common';
import Alert from 'src/components/common/Alert';
import { useComponentDidMount, useComponentWillUnmount } from 'src/hooks';
import { useLogin, useProfile } from 'src/queries';
import { ErrorService, Navigator, Toastify } from 'src/services';
import { getLocationState } from 'src/utils';
import { UAMBody } from '../common';
import MFAConfirmation from '../common/MFAConfirmation';
import { initialSignInFormValue, SIGNIN_KEY, signInFormSchema, SignInFormValue } from './helpers';

const Signin: React.FC = () => {
  const location = useLocation<string>();

  const formRef = useRef<FormikProps<SignInFormValue>>(null);

  const [userInfo, setUserInfo] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  useComponentWillUnmount(() => {
    setUserInfo(null);
  });

  useComponentDidMount(() => {
    const state = getLocationState(location);
    if (state?.username) {
      setFieldValue(SIGNIN_KEY.USER_NAME, state.username);
    }
  });

  const { login, isSigning } = useLogin({
    onSuccess(data, variables, context) {
      if (data.challengeName === 'CUSTOM_CHALLENGE') {
        setUserInfo({ user: data, signInPayload: variables });
      }
    },
    onError(error) {
      handleError(error);
    },
  });

  const { loading } = useProfile();

  const handleLogin = (values: SignInFormValue) => {
    const { username, password } = values;

    login({ username: username.trim(), password });
  };

  const handleError = (error: AuthError) => {
    switch (error.code.trim()) {
      case ErrorService.TYPES.NotAuthorizedException:
      case ErrorService.TYPES.UserNotFoundException:
        return setErrorMessage(ErrorService.MESSAGES.incorrectAccount);

      case ErrorService.TYPES.UsernameExistsException:
        return setErrorMessage(ErrorService.MESSAGES.incorrectCredentials);
      case ErrorService.TYPES.UserLambdaValidationException:
        return Toastify.error(ErrorService.MESSAGES.approvalRequiredAccount);

      default:
        return;
    }
  };

  const { values, errors, touched, getFieldProps, handleSubmit, setFieldValue } = useFormik({
    initialValues: initialSignInFormValue,
    onSubmit: handleLogin,
    validationSchema: signInFormSchema,
    innerRef: formRef,
  });

  // =========================== FORGOT PASSWORD ===========================
  const handleForgotPassword = () => {
    Navigator.navigate(PATHS.forgotPassword, { username: values.username });
  };

  const getErrorMessage = (fieldName: SIGNIN_KEY) => {
    // eslint-disable-next-line security/detect-object-injection
    return touched[fieldName] && errors[fieldName] ? errors[fieldName] : '';
  };

  const handleBackToSignIn = () => {
    setUserInfo(null);
  };

  return !userInfo ? (
    <UAMBody title="Disability Compensation Division" subTitle="Welcome to" renderIf={!userInfo}>
      <Alert className="mt-32" severity="info">
        Important! Keep your username and password private. Sharing your account username and
        password with unauthorized parties could compromise your security or the security of your
        business and reveal sensitive information about you or your clients.
      </Alert>
      <Form onSubmit={handleSubmit} autoComplete="off" className="mt-32">
        <Grid.Wrap>
          <Grid.Item variant="is-full">
            <Input
              label="Username"
              required
              placeholder="Username"
              errorMessage={getErrorMessage(SIGNIN_KEY.USER_NAME)}
              {...getFieldProps(SIGNIN_KEY.USER_NAME)}
            />
          </Grid.Item>
          <Grid.Item variant="is-full">
            <InputPassword
              label="Password"
              required
              autoComplete="current-password"
              placeholder="Password"
              errorMessage={getErrorMessage(SIGNIN_KEY.PASSWORD)}
              {...getFieldProps(SIGNIN_KEY.PASSWORD)}
            />
          </Grid.Item>
          {errorMessage && (
            <Grid.Item variant="is-full">
              <Text className="text-error">{errorMessage}</Text>
            </Grid.Item>
          )}
          <Grid.Item variant="is-full">
            <View align={'flex-end'}>
              <Link className={'text-is-16'} onClick={handleForgotPassword}>
                Forgot Password?
              </Link>
            </View>
          </Grid.Item>
          <Grid.Item variant="is-full">
            <Button
              type="submit"
              variant="default"
              className=""
              isFull
              isLoading={isSigning || loading}
            >
              Sign In
            </Button>
          </Grid.Item>
          <Grid.Item variant="is-full">
            <View flexGrow={1}>
              <Text className="text-center my-2" size={16}>
                Don’t have account?
                <Link
                  className={'ml-1 text-is-16'}
                  onClick={() => Navigator.navigate(PATHS.signUp)}
                >
                  Create Account
                </Link>
              </Text>
            </View>
          </Grid.Item>
        </Grid.Wrap>
      </Form>
    </UAMBody>
  ) : (
    <MFAConfirmation
      user={userInfo?.user}
      signInPayload={userInfo?.signInPayload}
      handleClickBack={handleBackToSignIn}
    />
  );
};

export default Signin;
