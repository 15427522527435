import { useFormikContext } from 'formik';
import React, { createContext, useContext } from 'react';
import { SignUpFormKey, SignupFormValues } from '../helpers';

interface SignUpContextType {
  getErrorMessage: (fieldName: SignUpFormKey) => string;
  handleChangeState: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface SignUpProviderProps {
  children: React.ReactNode;
}

const SignUpContext = createContext<SignUpContextType>({
  getErrorMessage: null,
  handleChangeState: null,
});

export const SignUpProvider = ({ children }: SignUpProviderProps) => {
  const { touched, errors, setFieldValue } = useFormikContext<SignupFormValues>();

  const getErrorMessage = (fieldName: SignUpFormKey) => {
    // eslint-disable-next-line security/detect-object-injection
    return touched[fieldName] && errors[fieldName] ? (errors[fieldName] as string) : '';
  };

  const handleChangeState = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFieldValue(name, value.toUpperCase());
  };

  return (
    <SignUpContext.Provider value={{ getErrorMessage, handleChangeState }}>
      {children}
    </SignUpContext.Provider>
  );
};

export const useSignUp = () => useContext(SignUpContext);
