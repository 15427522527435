import React from 'react';
import PersonalInformation from '../PersonalInformation';
import ClaimantInformation from './ClaimantInformation';

const LegalGuardianQuestion: React.FC<Props> = () => {
  return (
    <>
      <PersonalInformation />
      <ClaimantInformation />
    </>
  );
};

type Props = {};

export default LegalGuardianQuestion;
