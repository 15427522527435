import { useFormikContext } from 'formik';
import React from 'react';
import { Grid, RadioButton } from 'src/components/common';
import { SignUpFormKey, SignupFormValues } from '../helpers';
import { adjusterTypeOptions } from './helpers';
import { useSignUp } from '../SignUpProvider';

const AdjusterTypeCheck: React.FC<Props> = () => {
  const { getFieldProps, setFieldValue } = useFormikContext<SignupFormValues>();

  const { getErrorMessage } = useSignUp();

  return (
    <Grid.Item variant="is-full">
      <RadioButton
        label="Self-insured or Adjusting Company "
        options={adjusterTypeOptions}
        columns={1}
        errorMessage={getErrorMessage(SignUpFormKey.ThirdPartyAdminType)}
        {...getFieldProps(SignUpFormKey.ThirdPartyAdminType)}
        onChange={setFieldValue}
      />
    </Grid.Item>
  );
};

type Props = {};

export default AdjusterTypeCheck;
