import { useFormikContext } from 'formik';
import React from 'react';
import { Grid, Input, InputMask, InputPhone, Select } from 'src/components/common';
import EmailSection from '../EmailSection';
import {
  IdentificationType,
  SignUpFormKey,
  SignupFormValues,
  USERNAME_REGEX,
  UserType,
  identificationTypeOptions,
} from '../helpers';
import SectionText from '../SectionText';
import { useSignUp } from '../SignUpProvider';

const PersonalInformation: React.FC<Props> = ({ emailLabel }) => {
  const { getFieldProps, values, setFieldValue, setFieldTouched } =
    useFormikContext<SignupFormValues>();

  const { getErrorMessage } = useSignUp();

  const handleIdentificationTypeChange = (name, value) => {
    setFieldValue(name, value);
    setFieldValue(SignUpFormKey.IdentificationNumber, '');
    setFieldTouched(SignUpFormKey.IdentificationNumber, false);
  };

  const handleChangeLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setFieldValue(
      SignUpFormKey.Username,
      `${values?.[SignUpFormKey.FirstName]}${value}`.toLocaleLowerCase().replace(USERNAME_REGEX, '')
    );
  };

  const handleChangeFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setFieldValue(
      SignUpFormKey.Username,
      `${value}${values?.[SignUpFormKey.LastName]}`.toLocaleLowerCase().replace(USERNAME_REGEX, '')
    );
  };

  return (
    <>
      <SectionText section="Personal Information" />
      <Grid.Item>
        <Input
          label={`Last Name`}
          required
          placeholder="Enter your last name"
          errorMessage={getErrorMessage(SignUpFormKey.LastName)}
          maxLength={40}
          {...getFieldProps(SignUpFormKey.LastName)}
          onChange={handleChangeLastName}
        />
      </Grid.Item>
      <Grid.Item>
        <Input
          label={`First Name`}
          required
          placeholder="Enter your first name"
          errorMessage={getErrorMessage(SignUpFormKey.FirstName)}
          maxLength={30}
          {...getFieldProps(SignUpFormKey.FirstName)}
          onChange={handleChangeFirstName}
        />
      </Grid.Item>
      <Grid.Item>
        <Input
          label={'Username'}
          required
          placeholder="Enter your username"
          errorMessage={getErrorMessage(SignUpFormKey.Username)}
          {...getFieldProps(SignUpFormKey.Username)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const { name, value } = e.target;
            setFieldValue(name, value.replace(USERNAME_REGEX, ''));
          }}
          value={values?.[SignUpFormKey.Username]?.replace(USERNAME_REGEX, '')}
        />
      </Grid.Item>
      <Grid.Item renderIf={values?.[SignUpFormKey.UserType] === UserType.LegalGuardian}>
        <Select
          label={'Identification Type'}
          options={identificationTypeOptions}
          errorMessage={getErrorMessage(SignUpFormKey.IdentificationType)}
          {...getFieldProps(SignUpFormKey.IdentificationType)}
          onChange={handleIdentificationTypeChange}
          isClearable={false}
          required
          onBlur={setFieldTouched}
        />
      </Grid.Item>
      <Grid.Item renderIf={values?.[SignUpFormKey.UserType] === UserType.LegalGuardian}>
        <InputMask
          label={`Identification Number`}
          mask={
            values[SignUpFormKey.IdentificationType] === IdentificationType.SSN
              ? '999999999'
              : '***********'
          }
          placeholder="Enter your Identification Number"
          required
          disabled={!values[SignUpFormKey.IdentificationType]}
          errorMessage={getErrorMessage(SignUpFormKey.IdentificationNumber)}
          {...getFieldProps(SignUpFormKey.IdentificationNumber)}
        />
      </Grid.Item>
      <Grid.Item>
        <InputPhone
          international={false}
          label={`Contact Number`}
          required
          placeholder="Enter your contact number"
          errorMessage={getErrorMessage(SignUpFormKey.ContactNumber)}
          {...getFieldProps(SignUpFormKey.ContactNumber)}
          countries={['US']}
          onChange={setFieldValue}
        />
      </Grid.Item>
      <EmailSection emailLabel={emailLabel} />
    </>
  );
};

type Props = {
  emailLabel?: string;
};

export default PersonalInformation;
