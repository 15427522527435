import { useFormikContext } from 'formik';
import React from 'react';
import { Grid, Select } from 'src/components/common';
import { isEmpty } from 'src/validations';
import { useSignUp } from '../SignUpProvider';
import {
  initialSignupFormValues,
  ProgramType,
  programTypeOptions,
  SignUpFormKey,
  SignupFormValues,
} from '../helpers';
import { getUserClassificationOptions, getUserTypeOptions } from './helpers';

const UserTypeQuestion: React.FC = () => {
  const { getFieldProps, values, resetForm, setFieldValue } = useFormikContext<SignupFormValues>();
  const { getErrorMessage } = useSignUp();

  const handleChangeProgramType = (name: SignUpFormKey, value: ProgramType[]) => {
    resetForm({
      values: initialSignupFormValues,
    });
    setFieldValue(name, value);
  };

  const handleChangeUserClassification = (name: SignUpFormKey, value: string) => {
    resetForm({
      values: {
        ...initialSignupFormValues,
        [SignUpFormKey.ProgramType]: values?.[SignUpFormKey.ProgramType],
      },
    });
    setFieldValue(name, value);
  };

  const handleChangeUserType = (name: SignUpFormKey, value: string) => {
    resetForm({
      values: {
        ...initialSignupFormValues,
        [SignUpFormKey.UserClassification]: values?.[SignUpFormKey.UserClassification],
        [SignUpFormKey.ProgramType]: values?.[SignUpFormKey.ProgramType],
      },
    });
    setFieldValue(name, value);
  };

  return (
    <>
      <Grid.Item variant="is-full">
        <Select
          label={'Program Type'}
          options={programTypeOptions}
          errorMessage={getErrorMessage(SignUpFormKey.ProgramType)}
          {...getFieldProps(SignUpFormKey.ProgramType)}
          onChange={handleChangeProgramType}
          isClearable={false}
          required
          isMulti
          placeholder="Select"
        />
      </Grid.Item>
      <Grid.Item variant="is-full">
        <Select
          label={'User Classification'}
          options={getUserClassificationOptions(values?.[SignUpFormKey.ProgramType])}
          errorMessage={getErrorMessage(SignUpFormKey.UserClassification)}
          {...getFieldProps(SignUpFormKey.UserClassification)}
          onChange={handleChangeUserClassification}
          isClearable={false}
          required
          isDisabled={isEmpty(values?.[SignUpFormKey.ProgramType])}
          placeholder="Select"
        />
      </Grid.Item>
      <Grid.Item variant="is-full">
        <Select
          label={'User Type'}
          options={getUserTypeOptions(
            values?.[SignUpFormKey.UserClassification],
            values?.[SignUpFormKey.ProgramType]
          )}
          errorMessage={getErrorMessage(SignUpFormKey.UserType)}
          {...getFieldProps(SignUpFormKey.UserType)}
          onChange={handleChangeUserType}
          isClearable={false}
          required
          isDisabled={isEmpty(values?.[SignUpFormKey.UserClassification])}
          placeholder="Select"
        />
      </Grid.Item>
    </>
  );
};

export default UserTypeQuestion;
