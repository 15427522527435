import {
  isContain,
  isParentArray,
  ProgramType,
  UserClassification,
  userClassificationOptions,
  userTypeOptions,
} from '../helpers';

export const getUserClassificationOptions = (programType: ProgramType[]) =>
  userClassificationOptions.map((option) => ({
    ...option,
    isHide:
      isContain(programType, option.notInProgramTypeCondition) ||
      (!!option?.programTypeCondition && isParentArray(programType, option?.programTypeCondition)),
  }));

export const getUserTypeOptions = (
  userClassification: UserClassification,
  programType: ProgramType[]
) =>
  userTypeOptions.map((option) => ({
    ...option,
    isHide:
      !isContain([userClassification], option.userClassificationCondition) ||
      (option?.programTypeCondition && isContain(programType, option?.programTypeCondition)),
  }));
