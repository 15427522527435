import backgroundUAM from 'src/assets/images/background-uam.png';
import background from 'src/assets/images/background.svg';
import backgroundLogin from 'src/assets/images/background_login.webp';
import datahouseLogo from 'src/assets/images/datahouse-logo.svg';
import datahouseMiniLogo from 'src/assets/images/datahouse-mini-logo.svg';
import dcdLogo from 'src/assets/images/dcd-logo.png';
import defaultAvatar from 'src/assets/images/default-avatar.svg';
import defaultUser from 'src/assets/images/default-user.png';

export const IMAGES = {
  background,
  backgroundLogin,
  defaultUser,
  datahouseLogo,
  datahouseMiniLogo,
  defaultAvatar,
  dcdLogo,
  backgroundUAM,
};
