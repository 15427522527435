import { useFormikContext } from 'formik';
import React from 'react';
import { Checkbox, Grid, Text, View } from 'src/components/common';
import { SignUpFormKey, SignupFormValues } from '../helpers';

const ThirdPartyConfirm: React.FC<Props> = () => {
  const { getFieldProps, values } = useFormikContext<SignupFormValues>();

  return (
    <Grid.Item variant="is-full">
      <View align="center" isRowWrap>
        <Checkbox.Item
          {...getFieldProps(SignUpFormKey.IsThirdPartyAdmin)}
          checked={values?.[SignUpFormKey.IsThirdPartyAdmin]}
        />
        <Text>Are you a Third-Party Administrator?</Text>
      </View>
    </Grid.Item>
  );
};

type Props = {};

export default ThirdPartyConfirm;
