import { ReactNode } from 'react';
import { IMAGES } from 'src/appConfig/images';
import { Image, Text, View } from 'src/components/common';
import './styles.scss';
import { Typography } from '@material-ui/core';

const Header: React.FC<Props> = ({ title = '', subTitle = '' }) => {
  return (
    <View className="cpn-header" justify={'center'} align="center">
      <Image className="cpn-header__logo" src={IMAGES.dcdLogo} />
      {subTitle ? <Typography className="cpn-header__subTitle">{subTitle}</Typography> : ''}
      <Text size={32} className="cpn-header__title" variant="title">
        {title}
      </Text>
    </View>
  );
};

type Props = {
  title?: string | ReactNode;
  subTitle?: string;
};

export default Header;
