import cn from 'classnames';
import { FormikErrors, FormikProvider, useFormik } from 'formik';
import React, { Suspense, useState } from 'react';
import { PATHS } from 'src/appConfig/paths';
import { Button, Checkbox, Form, Grid, NavLink, Text, View } from 'src/components/common';
import { useSignUp } from 'src/queries';
import { Navigator, Toastify } from 'src/services';
import { UAMBody } from '../common';
import EmailConfirmationModal from '../common/EmailConfirmationModal';
import AdjusterTypeCheck from './AdjusterTypeCheck';
import {
  SignUpFormKey,
  SignupFormValues,
  UserClassification,
  UserType,
  initialSignupFormValues,
  signupFormValidationSchema,
} from './helpers';
import LegalGuardianQuestion from './LegalGuardianQuestion';
import { SignUpProvider } from './SignUpProvider';
import ThirdPartyConfirm from './ThirdPartyConfirm';
import UserTypeQuestion from './UserTypeQuestion';

const EmployeeQuestion = React.lazy(() => import('./EmployeeQuestion'));
const AdjusterQuestion = React.lazy(() => import('./AdjusterQuestion'));
const CarrierQuestion = React.lazy(() => import('./CarrierQuestion'));
const ContractorQuestion = React.lazy(() => import('./ContractorQuestion'));
const DependentQuestion = React.lazy(() => import('./DependentQuestion'));
const GovernmentAgencyQuestion = React.lazy(() => import('./GovernmentAgencyQuestion'));
const EmployerQuestion = React.lazy(() => import('./EmployerQuestion'));
const PhysicianQuestion = React.lazy(() => import('./PhysicianQuestion'));
const RepresentativeQuestion = React.lazy(() => import('./RepresentativeQuestion'));
const ThirdPartyQuestion = React.lazy(() => import('./ThirdPartyQuestion'));

const EmployerSignUp: React.FC = () => {
  const [userId, setUserId] = useState('');

  const handleSignUp = (values: SignupFormValues) => {
    const payload = {
      [SignUpFormKey.ProgramType]: values[SignUpFormKey.ProgramType],
      [SignUpFormKey.UserClassification]: values[SignUpFormKey.UserClassification],
      [SignUpFormKey.UserType]: values[SignUpFormKey.UserType],
      [SignUpFormKey.FirstName]: values[SignUpFormKey.FirstName],
      [SignUpFormKey.LastName]: values[SignUpFormKey.LastName],
      [SignUpFormKey.Username]: values[SignUpFormKey.Username],
      [SignUpFormKey.Email]: values[SignUpFormKey.Email],
      [SignUpFormKey.Password]: values[SignUpFormKey.Password],
      [SignUpFormKey.OrganizationInfo]: {
        [SignUpFormKey.ContactNumber]: values[SignUpFormKey.OrganizationContactNumber],
        [SignUpFormKey.AdjusterID]: values[SignUpFormKey.AdjusterID],
        [SignUpFormKey.InsuranceCarrierName]: values[SignUpFormKey.InsuranceCarrierName],
        [SignUpFormKey.FederalIDNumber]: values[SignUpFormKey.FederalIDNumber],
        [SignUpFormKey.Address]: values[SignUpFormKey.EOFAddress],
        [SignUpFormKey.City]: values[SignUpFormKey.EOFCity],
        [SignUpFormKey.ZipCode]: values[SignUpFormKey.EOFZipCode],
        [SignUpFormKey.State]: values[SignUpFormKey.EOFState],
        [SignUpFormKey.IsThirdPartyAdmin]: values[SignUpFormKey.IsThirdPartyAdmin],
        [SignUpFormKey.ParentCompanyName]: values[SignUpFormKey.ParentCompanyName],
        [SignUpFormKey.MailingAddress]: values[SignUpFormKey.MailingAddress],
        [SignUpFormKey.MailingCity]: values[SignUpFormKey.MailingCity],
        [SignUpFormKey.MailingState]: values[SignUpFormKey.MailingState],
        [SignUpFormKey.MailingZipCode]: values[SignUpFormKey.MailingZipCode],
        [SignUpFormKey.NatureOfBusiness]: values[SignUpFormKey.NatureOfBusiness],
        [SignUpFormKey.OrganizationType]: values[SignUpFormKey.OrganizationType],
        [SignUpFormKey.AdjustingServicesName]: values[SignUpFormKey.AdjustingServicesName],
        [SignUpFormKey.AdjustingServices]: values[SignUpFormKey.AdjustingServices],
        [SignUpFormKey.ThirdPartyAdminType]: values[SignUpFormKey.ThirdPartyAdminType],
        [SignUpFormKey.EmployerName]: values[SignUpFormKey.EmployerName],
      },
      [SignUpFormKey.ContactNumber]: values[SignUpFormKey.ContactNumber],
    };

    signup(payload);
  };

  const validate = (values: SignupFormValues) => {
    let errors: FormikErrors<SignupFormValues> = {
      confirmPassword: undefined,
    };

    if (values?.password !== values?.confirmPassword) {
      errors.confirmPassword = `“New Password” and “Confirm Password” must match`; // pragma: allowlist secret
    } else {
      delete errors.confirmPassword;
    }

    return errors;
  };

  const formik = useFormik<SignupFormValues>({
    initialValues: initialSignupFormValues,
    onSubmit: handleSignUp,
    validationSchema: signupFormValidationSchema,
    enableReinitialize: true,
    validate,
  });

  const { values, getFieldProps, handleSubmit, setFieldError } = formik || {};

  const isUserType = (userType: UserType) => values?.[SignUpFormKey.UserType] === userType;
  const isUserClassification = (userClassification: UserClassification) =>
    values?.[SignUpFormKey.UserClassification] === userClassification;

  const { signup, isSigningUp } = useSignUp({
    onSuccess: ({ data }) => {
      setUserId(data?.id);
    },
    onError: ({ message }) => {
      if (
        message === 'This username is already in use.' ||
        message.startsWith('Username is invalid')
      ) {
        setFieldError(SignUpFormKey.Username, message);
      } else {
        Array.isArray(message)
          ? message.forEach((message) => Toastify.error(message))
          : Toastify.error(message);
      }
    },
  });

  return (
    <Suspense fallback={null}>
      {!userId ? (
        <UAMBody>
          <Text size={36} className={cn('fw-medium mb-8 text-color-grey-900 text-align-center')}>
            Sign Up
          </Text>
          <FormikProvider value={formik}>
            <SignUpProvider>
              <Form onSubmit={handleSubmit} autoComplete="off">
                <Grid.Wrap>
                  <UserTypeQuestion />
                  <Suspense fallback={null}>
                    {(isUserType(UserType.Dependent) || isUserType(UserType.Spouse)) && (
                      <DependentQuestion />
                    )}
                    {(isUserType(UserType.Dependent) ||
                      isUserType(UserType.Spouse) ||
                      isUserType(UserType.Employee)) && <EmployeeQuestion />}
                    {((isUserClassification(UserClassification.Employer) &&
                      values?.[SignUpFormKey.UserType]) ||
                      isUserType(UserType.SelfInsured)) && <EmployerQuestion />}
                    {isUserType(UserType.Carrier) && <CarrierQuestion />}
                    {isUserType(UserType.ThirdPartyAdmin) && <ThirdPartyQuestion />}
                    {isUserClassification(UserClassification.HealthCareProvider) &&
                      values?.[SignUpFormKey.UserType] && <PhysicianQuestion />}
                    {(isUserType(UserType.BillReview) ||
                      isUserType(UserType.VocationalRehabilitationCounselor) ||
                      isUserClassification(UserClassification.Legal)) &&
                      values?.[SignUpFormKey.UserType] && <RepresentativeQuestion />}
                    {isUserType(UserType.LegalGuardian) && <LegalGuardianQuestion />}
                    {isUserType(UserType.Contractor) && <ContractorQuestion />}
                    {isUserClassification(UserClassification.GovernmentAgency) && (
                      <GovernmentAgencyQuestion />
                    )}
                    {isUserType(UserType.Adjuster) && <AdjusterQuestion />}
                    {(isUserType(UserType.Employer) ||
                      isUserType(UserType.Physician) ||
                      isUserType(UserType.BillReview) ||
                      isUserType(UserType.VocationalRehabilitationCounselor) ||
                      isUserType(UserType.Adjuster) ||
                      isUserType(UserType.Carrier)) && <ThirdPartyConfirm />}
                    {isUserType(UserType.Adjuster) && values[SignUpFormKey.IsThirdPartyAdmin] && (
                      <AdjusterTypeCheck />
                    )}
                  </Suspense>
                  <Grid.Item variant="is-full">
                    <View align="center" isRowWrap>
                      <Checkbox.Item
                        {...getFieldProps(SignUpFormKey.IsAgreement)}
                        checked={values?.[SignUpFormKey.IsAgreement]}
                      />
                      <Text>
                        By submitting this request, I agree to abide by all laws and restrictions
                        governing the personal information of individuals and organizations involved
                        in a case.
                      </Text>
                    </View>
                  </Grid.Item>
                  <Grid.Item variant="is-full">
                    <View flexGrow={1}>
                      <Button
                        type="submit"
                        isLoading={isSigningUp}
                        disabled={
                          !values[SignUpFormKey.IsAgreement] ||
                          !(
                            isUserType(UserType.Adjuster) ||
                            isUserType(UserType.Carrier) ||
                            isUserType(UserType.SelfInsured)
                          )
                        }
                      >
                        Register
                      </Button>
                    </View>
                  </Grid.Item>
                </Grid.Wrap>
              </Form>
            </SignUpProvider>
          </FormikProvider>
          <Text className="text-center pt-32">
            <NavLink className={'fw-medium text-is-16'} to={PATHS.signIn}>
              Back to Login
            </NavLink>
          </Text>
        </UAMBody>
      ) : (
        <EmailConfirmationModal
          username={userId}
          handleClickBack={() => Navigator.navigate(PATHS.signIn)}
        />
      )}
    </Suspense>
  );
};

export default EmployerSignUp;
