import { useFormikContext } from 'formik';
import React from 'react';
import {
  Grid,
  Input,
  InputPassword,
  PasswordSharingMessage,
  ValidatePassword,
} from 'src/components/common';
import { SignUpFormKey, SignupFormValues, UserType } from '../helpers';
import './styles.scss';
import { useSignUp } from '../SignUpProvider';

const EmailSection: React.FC<Props> = ({ emailLabel = 'your Work Email Address' }) => {
  const { getFieldProps, values } = useFormikContext<SignupFormValues>();

  const { getErrorMessage } = useSignUp();

  const emailFieldName =
    values?.[SignUpFormKey.UserType] === UserType.Dependent ||
    values?.[SignUpFormKey.UserType] === UserType.Spouse
      ? SignUpFormKey.DSEmailAddress
      : SignUpFormKey.Email;

  return (
    <>
      <Grid.Item>
        <Input
          label={emailLabel}
          required
          placeholder={`Enter ${emailLabel?.toLocaleLowerCase()}`}
          errorMessage={getErrorMessage(emailFieldName)}
          {...getFieldProps(emailFieldName)}
          containerClassName="cmp-email-container"
        />
      </Grid.Item>
      <Grid.Item variant="is-full">
        <PasswordSharingMessage />
      </Grid.Item>
      <Grid.Item>
        <InputPassword
          label="Password"
          required
          placeholder="Enter your password"
          errorMessage={getErrorMessage(SignUpFormKey.Password)}
          {...getFieldProps(SignUpFormKey.Password)}
        />
        <ValidatePassword password={values?.password} />
      </Grid.Item>
      <Grid.Item>
        <InputPassword
          label="Confirm Password"
          required
          placeholder="Enter your confirm password"
          errorMessage={getErrorMessage(SignUpFormKey.ConfirmPassword)}
          {...getFieldProps(SignUpFormKey.ConfirmPassword)}
        />
      </Grid.Item>
    </>
  );
};

type Props = {
  emailLabel?: string;
};

export default EmailSection;
