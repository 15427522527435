import React from 'react';
import SectionText from '../../SectionText';
import AddressSection from '../AddressSection';
import MailingAddressSection from '../MailingAddressSection';

const ClaimantAddress: React.FC<Props> = () => {
  return (
    <>
      <SectionText section="Claimant Address" />
      <AddressSection />
      <MailingAddressSection />
    </>
  );
};

type Props = {};

export default ClaimantAddress;
